import { Component, OnInit, Input, output } from '@angular/core';
import { isNullOrUndefined } from 'util';

@Component({
  selector: 'app-privacy-consent-container',
  templateUrl: './privacy-consent-container.component.html',
  styleUrls: ['./privacy-consent-container.component.scss'],
  standalone: false,
})
export class PrivacyConsentContainerComponent implements OnInit {
  // TODO: Skipped for migration because:
  //  Your application code writes to the input. This prevents migration.
  @Input() displayDialog = false;

  readonly policyAcceptedEvent = output();

  constructor() {}

  ngOnInit() {}

  policyAccepted(): void {
    this.displayDialog = false;
    this.policyAcceptedEvent.emit();
  }
}
