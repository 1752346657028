@if (show()) {
  <p-toolbar>
    <div class="ui-toolbar-group">
      <div id="toolbar-menu-button">
        <button
          pButton
          styleClass="menu-button"
          icon="fa fa-bars"
          (click)="menuToggle.emit($event)"
        ></button>
      </div>
      <div class="menu-title">
        {{ title() }}
      </div>
      @if (canChangeTheme) {
        <p-selectButton
          [options]="themeOptions"
          [(ngModel)]="theme"
          (onChange)="changeTheme()"
          class="theme-select"
        ></p-selectButton>
      }
    </div>
  </p-toolbar>
}
