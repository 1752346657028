import { Component, OnInit, inject, input, output } from '@angular/core';
import { DeviceSize } from 'src/app/core/enums/deviceSize.enum';
import { AuthenticatedComponent } from './authenticated.component';
import { ThemeService } from 'src/app/services/theme.service';
import { SelectItem } from 'primeng/api';
import { AuthApiService } from 'src/app/services/auth-api.service';
import { AppTheme } from 'src/app/services/theme.service';
import { CLAIMS } from 'src/app/constants/auth-constants';

@Component({
    selector: 'app-toolbar',
    templateUrl: './toolbar.component.html',
    styleUrls: ['./toolbar.component.scss'],
    standalone: false
})
export class ToolbarComponent extends AuthenticatedComponent implements OnInit {
  private _themeService = inject(ThemeService);
  private _authApi = inject(AuthApiService);

  readonly title = input(undefined);
  readonly sessionExpire = input(-1);
  readonly networkStatus = input('online');
  readonly show = input(true);
  readonly menuToggle = output<any>();
  readonly logoutEvent = output<any>();
  readonly name = input(undefined);

  canChangeTheme: boolean = false;
  themeOptions: SelectItem[] = [
    {
      label: 'Light',
      value: 'saga-green',
      disabled: true,
    },
    {
      label: 'Dark',
      value: 'arya-green',
      disabled: false,
    },
  ];
  theme: AppTheme = this.themeOptions[0].value;

  ngOnInit() {
    this._authApi.userClaimsUpdated.asObservable().subscribe((_) => {
      this.canChangeTheme =
        this._authApi.doesUserHaveClaim(CLAIMS.CHANGE_CRLINC_THEME);
    });

    this._themeService.themeChanged
      .asObservable()
      .subscribe((selectedTheme) => {
        this.theme = selectedTheme;
        this.themeOptions.forEach((x) => (x.disabled = false));
        this.themeOptions.find((x) => x.value === this.theme).disabled = true;
      });
  }

  isLargeDevice(): boolean {
    if (!this.deviceSize()) return true; // Err on the side of caution
    return this.ifDeviceMatches([DeviceSize.LG, DeviceSize.XL]);
  }

  changeTheme() {
    this.themeOptions.forEach((x) => (x.disabled = false));
    this.themeOptions.find((x) => x.value === this.theme).disabled = true;
    this._themeService.switchTheme(this.theme);
  }
}
