import { Component, input } from '@angular/core';
import { DeviceComponent } from '../../core/components/device.component';

/**
 * Base class to indicate if a component is authenticated and can be shown.
 */
@Component({
    template: '',
    standalone: false
})
export class AuthenticatedComponent extends DeviceComponent {
  readonly show = input<boolean>(undefined);
}
